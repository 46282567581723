
import {defineComponent} from 'vue';
import UserPortalLabel from "@/components/common/label/UserPortalLabel.vue";

export default defineComponent({
  name: 'UserPortalListWithSwitch',
  components: {
    UserPortalLabel,
  },
  props: {
    name: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String
    },
    options: {
      required: true,
      type: Array
    },
    getOptionDisplayedValue: {
      required: true,
      type: Function
    },
    selectedOption: {
      required: true,
      type: String
    },
    maxHeight: {
      required: false,
      type: Number,
      default: 115
    },
    enabledOption: {
      required: true,
      type: String,
    },
    isOptionHighlighted: {
      required: false,
      type: Function
    },
    isMandatory: {
      required: false,
      type: Boolean
    },
  },
  methods: {
    updateSelectedOption(clickedOption: unknown): void {
      this.$emit("update:selectedOption", clickedOption);
    },
    isOptionSelected(currentOption: unknown): boolean {
      return this.getOptionDisplayedValue(this.selectedOption) === this.getOptionDisplayedValue(currentOption);
    },
    checkIfOptionIsHighlighted(option: string) {
      return this.isOptionHighlighted ? this.isOptionHighlighted(option) : true;
    },
  }
})
