
  import {defineComponent} from 'vue';
  import UserPortalLabel from "@/components/common/label/UserPortalLabel.vue";
  import UserForm from "@/components/user/form/UserForm.vue";
  import ResetPasswordForm from "@/components/user/form/ResetPasswordForm.vue";
  import User from "@/model/User";

  export default defineComponent({
    name: "EditUserView",
    components: {
      UserPortalLabel,
      UserForm,
      ResetPasswordForm
    },
    props: {
      user: {
        required: true,
        type: User
      }
    }
  });
