import {ActionContext} from "vuex";
import User from "@/model/User";
import UserApi from "@/api/UserApi";
import Groups from '../../constants/groups';
import GroupApi from "@/api/GroupApi";
import UserWithGroups from "@/model/UserWithGroups";

export interface UserState {
  users: Array<User>;
  groups: Array<string>;
  loggedUserGroups: Array<string>;
}

export default {
  namespaced: true as true,
  state: {
    users: [] as Array<User>,
    groups: [] as Array<string>,
    loggedUserGroups: [] as Array<string>
  } as UserState,
  actions: {
    async deleteUser(
      {commit}: ActionContext<UserState, UserState>,
      username: string
    ): Promise<void> {
      try {
        await UserApi.deleteUser(username);
        commit('DELETE_USER', username);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async createUser(
      {commit, rootGetters}: ActionContext<UserState, UserState>,
      savedUserWithGroups: UserWithGroups
    ): Promise<void> {
      try {
        const selectedOrganisationId = rootGetters['organisationModule/selectedOrganisationWithUser']?.organisation?.organisationId;
        if (selectedOrganisationId === undefined) {
          return Promise.reject(new Error('No Organization is selected'));
        }
        savedUserWithGroups.user.organisationId = selectedOrganisationId;
        await UserApi.createUser(savedUserWithGroups);
        commit('SAVE_USER', savedUserWithGroups);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async updateUser(
      {commit}: ActionContext<UserState, UserState>,
      userWithGroups: UserWithGroups
    ): Promise<void> {
      try {
        await UserApi.updateUser(userWithGroups);
        commit('UPDATE_USER', userWithGroups);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchAllUsersInOrganization(
      {commit, rootGetters}: ActionContext<UserState, UserState>,
    ): Promise<void> {
      try {
        const selectedOrganisationId = rootGetters['organisationModule/selectedOrganisationWithUser']?.organisation?.organisationId;
        if (selectedOrganisationId === undefined) {
          return Promise.reject(new Error('No Organization is selected'));
        }
        const users: Array<User> = await UserApi.getAllUsersInOrganization(selectedOrganisationId);
        commit('UPDATE_USERS', users);
        return Promise.resolve();
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },

    async fetchAllGroupsForUser(
      {commit}: ActionContext<UserState, UserState>, username: string,
    ): Promise<void> {
      try {
        const groups: Array<string> = await GroupApi.getGroupsForUser(username);
        commit('UPDATE_GROUPS', groups);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchAllGroupsForLoggedUser(
      {commit}: ActionContext<UserState, UserState>, username: string,
    ): Promise<void> {
      try {
        const groups: Array<string> = await GroupApi.getGroupsForUser(username);
        commit('UPDATE_GROUPS_FOR_LOGGED_USER', groups);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    }
  },

  mutations: {
    DELETE_USER(state: UserState, username: string): void {
      const index = state.users.findIndex(u => u.username === username);
      if (index !== -1) {
        state.users.splice(index, 1);
      }
    },

    SAVE_USER(state: UserState, savedUserWithGroups: UserWithGroups): void {
      const index = state.users.findIndex(u => u.username === savedUserWithGroups.user.username);
      if (index !== -1) {
        state.users.splice(index, 1);
      }
      state.users.push(savedUserWithGroups.user);
      state.groups = savedUserWithGroups.userGroups;
    },

    UPDATE_USER(state: UserState, userWithGroups: UserWithGroups): void {
      const index = state.users.findIndex(u => u.username === userWithGroups.user.username);
      if (index !== -1) {
        state.users[index].setEditableProperties(userWithGroups.user);
        state.groups = userWithGroups.userGroups;
      }
    },

    UPDATE_USERS(state: UserState, users: Array<User>): void {
      state.users.splice(0, state.users.length, ...users);
    },

    UPDATE_GROUPS(state: UserState, groups: Array<string>): void {
      state.groups = groups;
    },

    UPDATE_GROUPS_FOR_LOGGED_USER(state: UserState, groups: Array<string>): void {
      state.loggedUserGroups = groups;
    }
  },
  getters: {
    allUsers: (state: UserState): Array<User> => state.users,
    isOrganizationAdmin: (state: UserState): boolean => state.loggedUserGroups.includes(Groups.ORGANIZATION_ADMIN),
    isUtmAdmin: (state: UserState): boolean => state.loggedUserGroups.includes(Groups.UTM_ADMIN),
    getUser: (state: UserState) =>
      (username: string): User | undefined => state.users.find(user => user.username === username)
  },
}
