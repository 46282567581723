
  import {defineComponent} from 'vue';
  import OrganisationSelection from "@/components/organisation/selection/OrganisationSelection.vue";
  import PermissionUtils from "@/utils/PermissionUtils";

  export default defineComponent({
    name: 'ManagementMenu',
    components: {
      OrganisationSelection
    },
    computed: {
      displayOrganizationApiAccessLink(): boolean {
        return PermissionUtils.isAllowedToManageOrganizationSecret();
      },
      isOrganizationSelected(): boolean {
        return !!this.$store.direct.getters.organisationModule.selectedOrganisationWithUser?.organisation?.organisationId;
      }
    }
  });
