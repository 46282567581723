import Organisation from "@/model/Organisation";
import User from "@/model/User";

export default class OrganisationWithUser {
  public organisation: Organisation;
  public user: User;

  constructor(
    organisation: Organisation,
    user: User
  ) {
    this.organisation = organisation;
    this.user = user;
  }
}