import BaseApi from "@/api/BaseApi";
import ApiEndpoints from "@/constants/apiEndpoints";

class GroupApi extends BaseApi {

  public async setGroup2User(userName: string, groups: Array<string>): Promise<Response> {
    return await this.post(ApiEndpoints.GROUPS2USERS, groups, undefined,
      GroupApi.constructQueryParams('userName', userName));
  }

  public async getAllGroups(): Promise<Array<string>> {
    const apiResponse: Response = await this.get(ApiEndpoints.GROUPS);
    return await apiResponse.json();
  }

  public async getGroupsForUser(userName: string): Promise<Array<string>> {
    const apiResponse: Response = await this.get(ApiEndpoints.GROUPS4USERS, undefined,
      GroupApi.constructQueryParams('userName', userName));
    return await apiResponse.json();
  }

  private static constructQueryParams(key: string, value: string): Map<string, string> {
    const queryParam = JSON.parse('{"' + key + '": "' + value + '"}');
    return new Map(Object.entries(queryParam));
  }
}

export default new GroupApi();