import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "organisationList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.availableOrganisationIds, (organisationId) => {
      return (_openBlock(), _createBlock("div", {
        key: organisationId,
        class: {
        organisationIdItem: true,
        selected: organisationId === _ctx.selectedOrganisationId
      },
        onClick: ($event: any) => (this.selectOrganisationId(organisationId))
      }, _toDisplayString(organisationId), 11, ["onClick"]))
    }), 128))
  ]))
}