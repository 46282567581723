import logLevels, { LogLevel } from '../constants/logLevels';
import * as Keycloak from 'keycloak-js';

declare global {
  interface Window {
    userPortalEnv: any;
  }
}

function getEnv(name: string) {
  const windowConfig: any = (window as any).configs;

  if (windowConfig !== undefined && windowConfig[name] !== undefined) {
    return windowConfig[name];
  }
  return  process.env[name];
}

const secured = getEnv("VUE_APP_SECURED") === 'true';
const httpLabel: string = 'http' + (secured ? 's' : '');

class AppConfig {
  public build = '${buildNumber}';
  public version = '${version}';
  public serviceUrl: string = httpLabel + '://' + getEnv("VUE_APP_SERVER_URL");
  public keyclokConfig: Keycloak.KeycloakConfig = {
    realm: process.env.VUE_APP_AUTH_REALM || 'swim',
    url: httpLabel + '://' + getEnv("VUE_APP_AUTH_SERVICE_URL") + '/auth',
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID || 'dataprovider'
  };
  public keycloakLoginOptions: Keycloak.KeycloakLoginOptions = {
    idpHint: getEnv("process.env.VUE_APP_AUTH_IDP_HINT"),
  };
  public logLevel: LogLevel = logLevels[(process.env.VUE_APP_LOG_LEVEL || 'debug').toLowerCase().trim()];
  public snackbarTimeout: number = parseInt(process.env.VUE_APP_SNACKBAR_TIMEOUT || '2000') || 2000;
}

export default new AppConfig();