import featureTypes, {FeatureTypes} from "@/constants/FeatureTypes";

export default class FeatureTypeConverter {

  static getTypeForFeature(type: string): FeatureTypes {
    const featureType = Object.values(featureTypes).filter(feature => feature.name === type);
    return featureType.length > 0 ? featureType[0] : null as any;
  }

  static getFeatureTypesForService(service: string): Array<FeatureTypes> {
    return Object.values(featureTypes).filter(feature => feature.belongsToService === service);
  }

  static getServiceForFeatureName(featureName: string): string | null | undefined {
    const featureType: FeatureTypes | undefined = Object.values(featureTypes)
      .find(feature => feature.name === featureName && feature.belongsToService);
    return featureType?.belongsToService;
  }

}