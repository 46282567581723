import AppConfig from '../config/appConfig';
import logLevels, { LogLevel } from '../constants/logLevels';
import store from '../store';

export default class LoggerUtils {

  static debug(message: string) {
    LoggerUtils.log(logLevels.debug, message);
  }

  static info(message: string) {
    LoggerUtils.log(logLevels.info, message);
  }

  static warn(message: string) {
    LoggerUtils.log(logLevels.warn, message);
  }

  static error(message: string) {
    LoggerUtils.log(logLevels.error, message);
  }

  static fatal(message: string) {
    LoggerUtils.log(logLevels.fatal, message);
  }

  static log(level: LogLevel, message: string) {
    if (level.order >= AppConfig.logLevel.order) {
      console.log('[' + level.name + ']', Date.now(), store.getters.loginModule.username, message);
    }
  }
}