import TableAction from "@/model/ui/table/TableAction";
import TableField from "@/model/ui/table/TableField";

export default class TableConfiguration<T> {
  public displayedFields: Array<TableField<T>>;
  public actions: Array<TableAction<T>> | undefined;


  constructor(displayedFields: Array<TableField<T>>, actions?: Array<TableAction<T>>) {
    this.displayedFields = displayedFields;
    this.actions = actions;
  }
}