
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: "UserPortalOptionList",
    props: {
      name: {
        required: true,
        type: String
      },
      maxHeight: {
        required: true,
        type: Number
      },
      options: {
        required: true,
        type: Array
      },
      updateSelectedOptions: {
        required: true,
        type: Function
      },
      containsSearchInput: {
        required: true,
        type: Function
      },
      getOptionIdentifierValue: {
        required: true,
        type: Function
      },
      getOptionDisplayedValue: {
        required: true,
        type: Function
      },
      isOptionSelected: {
        required: true,
        type: Function
      },
      isDropdown: {
        required: false,
        type: Boolean,
        default: false
      },
      checkOptionIsDisabled: {
        required: true,
        type: Function
      }
    }
  });
