
import { defineComponent } from "vue";
import User from "../../model/User";
import LoggerUtils from "@/utils/LoggerUtils";
import SnackbarType from "@/constants/SnackbarType";
import UserPortalButton from "@/components/common/button/UserPortalButton.vue";
import UserPortalTable from "@/components/common/table/UserPortalTable.vue";
import TableConfiguration from "@/model/ui/table/TableConfiguration";
import TableField from "@/model/ui/table/TableField";
import TableAction from "@/model/ui/table/TableAction";
import DialogUtils from "@/utils/DialogUtils";
import PermissionUtils from "@/utils/PermissionUtils";

export default defineComponent({
  name: "UserManagement",
  components: {
    UserPortalButton,
    UserPortalTable,
  },
  async mounted(): Promise<void> {
    try {
      await this.$store.direct.dispatch.userModule.fetchAllUsersInOrganization();
    } catch (e) {
      LoggerUtils.error(
        `cannot initialize User Management component: ${e.message}`
      );
      await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
        message: `cannot initialize User Management component: ${e.message}`,
        type: SnackbarType.ERROR,
      });
    }
  },
  computed: {
    users(): Array<User> {
      return this.$store.direct.getters.userModule.allUsers as Array<User>;
    },
    showDetails(): boolean {
      return this.selected?.username !== undefined;
    },
    userTableConfiguration(): TableConfiguration<User> {
      return new TableConfiguration<User>(
        this.getUserDisplayedFields,
        this.getUserActions
      );
    },
    getUserDisplayedFields(): Array<TableField<User>> {
      return [
        new TableField("Username", (user: User) => user.username),
        new TableField("First Name", (user: User) => user.firstName),
        new TableField("Last Name", (user: User) => user.lastName),
        new TableField("E-mail", (user: User) => user.mail),
      ];
    },
    getUserActions(): Array<TableAction<User>> {
      return [
        new TableAction(
          "Edit",
          this.editUser,
          false,
          !PermissionUtils.isAllowedToEditUser()
        ),
        new TableAction(
          "Delete",
          this.deleteUser,
          true,
          !PermissionUtils.isAllowedToEditUser()
        ),
      ];
    },
    displayManageGroupsForm(): boolean {
      return (
        !!this.selected.username && PermissionUtils.isAllowedToManageGroups()
      );
    },
    selectedOrganisation(): string {
      return (
        this.$store.direct.getters.organisationModule.selectedOrganisationWithUser?.organisation?.organisationId || ""
      );
    },
  },
  data() {
    return {
      selected: {} as User,
    };
  },
  methods: {
    isAllowedToEditUser(): boolean {
      return !PermissionUtils.isAllowedToEditUser();
    },
    createNewUser(): void {
      this.$router.push("/createUser");
    },
    deleteUser(user: User): void {
      DialogUtils.confirmOperation({
        message: `Are you sure you want to delete the user "${user.username}"?`,
        yesAction: async (): Promise<void> => {
          try {
            await this.$store.direct.dispatch.userModule.deleteUser(
              user.username
            );
            this.$store.direct.dispatch.snackbarModule.displaySnackbar({
              message: `Successfully deleted user "${user.username}"`,
              type: SnackbarType.SUCCESS,
            });
            if (this.selected.username === user.username) {
              this.selected = {} as User;
            }
          } catch (e) {
            LoggerUtils.error(
              `Error occurred while trying to delete user. Reason: ${e.message}`
            );
            this.$store.direct.dispatch.snackbarModule.displaySnackbar({
              message: `Error occurred while trying to delete user.`,
              type: SnackbarType.ERROR,
            });
          }
        },
        noAction(): void {
          LoggerUtils.info(
            `Deletion operation for user ${user.username} was dropped.`
          );
        },
      });
    },
    editUser(user: User): void {
      this.$router.push(`/editUser?username=${user.username}`);
    },
    getUsername(user: User): string {
      return user.username;
    },
  },
});
