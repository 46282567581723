export default class TableAction<T> {
  public title: string;
  public isCriticalOperation: boolean;
  public disabled: boolean;
  public callback: (entity: T) => void;


  constructor(title: string, callback: (entity: T) => void, isCriticalOperation?: boolean, disabled?: boolean) {
    this.title = title;
    this.isCriticalOperation = isCriticalOperation || false;
    this.callback = callback;
    this.disabled = disabled || false;
  }
}