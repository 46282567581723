
  import {defineComponent} from 'vue';
  import {Button} from "@/store/modules/dialog";

  export default defineComponent({
    name: "Dialog",
    computed: {
      isDisplayed(): boolean {
        return this.$store.direct.getters.dialogModule.isDisplayed;
      },
      title(): string {
        return this.$store.direct.getters.dialogModule.title;
      },
      message(): string {
        return this.$store.direct.getters.dialogModule.message;
      },
      buttons(): Array<Button> {
        return [...this.$store.direct.getters.dialogModule.buttons];
      }
    },
    methods: {
      getClass(label: string) {
        return {
          confirmDialogButton: true,
          yesButton: label === 'Yes',
          noButton: label === 'No'
        };
      }
    }
  });
