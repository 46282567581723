import { toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("div", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (this.hide && this.hide(...args))),
    class: _ctx.getClass
  }, _toDisplayString(_ctx.message), 3)), [
    [_vShow, _ctx.isDisplayed]
  ])
}