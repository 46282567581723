export interface ServiceTypes {
  name: string;
  displayValue: string;
}

const serviceTypes: { [key: string]: ServiceTypes } = {};
serviceTypes['ALERTING'] = {
  name: 'ALERTING',
  displayValue: 'partner_alerting'
};

serviceTypes['TELEMETRY'] = {
  name: 'TELEMETRY',
  displayValue: 'partner_telemetry'
};

serviceTypes['UTM'] = {
  name: 'UTM',
  displayValue: 'partner_utm'
};

serviceTypes['RAS'] = {
  name: 'RAS',
  displayValue: 'partner_ras'
};

serviceTypes['FPL_CONVERTER'] = {
  name: 'FPL_CONVERTER',
  displayValue: 'partner_fplConverter'
};

export default serviceTypes
