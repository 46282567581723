import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "app"
}
const _hoisted_2 = { id: "content" }
const _hoisted_3 = { id: "management-menu" }
const _hoisted_4 = { id: "router-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_ManagementMenu = _resolveComponent("ManagementMenu")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Footer = _resolveComponent("Footer")
  const _component_UserPortalSnackbar = _resolveComponent("UserPortalSnackbar")
  const _component_Dialog = _resolveComponent("Dialog")

  return (this.isAuthenticated)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", null, [
          _createVNode(_component_Header)
        ]),
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_ManagementMenu)
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_router_view)
          ])
        ]),
        _createVNode("div", null, [
          _createVNode(_component_Footer)
        ]),
        _createVNode(_component_UserPortalSnackbar),
        _createVNode(_component_Dialog)
      ]))
    : _createCommentVNode("", true)
}