export default class ModelUtils {
  static compare(a: string, b: string) {
    if (a < b) {
      return -1;
    }
    if (b < a) {
      return 1;
    }
    return 0;
  }

  static sort(a: string, b: string) {
    return ModelUtils.compare(a, b);
  }
}