
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: "UserPortalSwitchButton",
    props: {
      enabled: {
        required: true,
        type: Boolean
      },
      enabledValue: {
        required: true,
        type: String,
      },
      disabledValue: {
        required: true,
        type: String,
      }
    },
    methods: {
      toggle(): void {
        this.$emit("updated-value", !this.enabled);
      }
    }
  });
