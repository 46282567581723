
  import {defineComponent} from "vue";
  import AppConfig from "./config/appConfig";
  import Header from "@/components/header/Header.vue";
  import Logger from "./utils/LoggerUtils";
  import LoggerUtils from "./utils/LoggerUtils";
  import Keycloak from "keycloak-js";
  import Footer from "@/components/footer/Footer.vue";
  import UserPortalSnackbar from "@/components/common/snackbar/UserPortalSnackbar.vue";
  import ManagementMenu from "@/components/left-menu/ManagementMenu.vue";
  import Dialog from "@/components/common/dialog/Dialog.vue";
  import AssetUtils from "@/utils/AssetUtils";

  export default defineComponent({
    name: "app",
    components: {
      Header,
      UserPortalSnackbar,
      ManagementMenu,
      Footer,
      Dialog
    },
    computed: {
      isAuthenticated(): boolean | undefined {
        return (
          this.$store.direct.state.loginModule.keycloak !== null &&
          this.$store.direct.state.loginModule.keycloak.authenticated
        );
      },
    },

    methods: {
      async login(): Promise<void> {
        const keycloak = new Keycloak(AppConfig.keyclokConfig);
        try {
          await keycloak.init({onLoad: "check-sso", checkLoginIframeInterval: 1});
          if (keycloak.authenticated) {
            const kctoken: string = keycloak.token == null ? "" : keycloak.token;
            sessionStorage.setItem("kctoken", kctoken);
            this.$store.direct.dispatch.loginModule.updateToken(keycloak);
            const userProfile: any = await keycloak.loadUserProfile();
            const providerId = userProfile['attributes'].providerId[0];
            await this.$store.direct.dispatch.loginModule.updateUserProfile(providerId);
            await AssetUtils.loadAssets();

            setInterval(() => {
              keycloak.updateToken(10).catch(() => keycloak.logout());
              const kctoken = keycloak.token == null ? "" : keycloak.token;
              sessionStorage.setItem("kctoken", kctoken);
              this.$store.direct.dispatch.loginModule.updateToken(keycloak);
            }, 10000);

          } else {
            keycloak.login({idpHint: AppConfig.keycloakLoginOptions.idpHint});
          }
          return await Promise.resolve();
        } catch (e) {
          LoggerUtils.error(e);
          return await Promise.reject(e);
        }
      }
    },

    mounted: async function () {
      Logger.debug("Application is mounted");
      // this.$router.push('/');
      await this.login();
    }
  })
  ;
