import {ActionContext} from "vuex";

export interface Button {
  label: string;
  action(): void;
}

export interface DialogState {
  display: boolean;
  title: string;
  message: string;
  buttons: Array<Button>;
}

interface DisplayDialogPayload {
  message: string;
  buttons: Array<Button>;
}

export default {
  namespaced: true as true,
  state: {
    display: false,
    title: 'Confirm operation:',
    message: '',
    buttons: []
  } as DialogState,
  getters: {
    isDisplayed: (state: DialogState): boolean => state.display,
    title: (state: DialogState): string => state.title,
    message: (state: DialogState): string => state.message,
    buttons: (state: DialogState): Array<Button> => state.buttons
  },
  actions: {
    async displayDialog(
      {commit}: ActionContext<DialogState, DialogState>,
      payload: DisplayDialogPayload
    ): Promise<void> {
      commit('DISPLAY_DIALOG', payload);
    },

    async hideDialog(
      {commit}: ActionContext<DialogState, DialogState>
    ): Promise<void> {
      commit('HIDE_DIALOG');
    }
  },
  mutations: {
    DISPLAY_DIALOG(state: DialogState, payload: DisplayDialogPayload): void {
      state.display = true;
      state.message = payload.message;
      state.buttons = payload.buttons;
    },

    HIDE_DIALOG(state: DialogState): void {
      state.display = false;
    }
  }
}
