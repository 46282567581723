import SnackbarType from "@/constants/SnackbarType";
import AppConfig from "@/config/appConfig";
import {ActionContext} from "vuex";

export interface SnackbarState {
  display: boolean;
  message: string;
  type: SnackbarType;
}

interface DisplaySnackbarPayload {
  message: string;
  type: SnackbarType;
}

export default {
  namespaced: true as true,
  state: {
    display: false,
    message: '',
    type: SnackbarType.SUCCESS
  } as SnackbarState,
  getters: {
    isDisplayed: (state: SnackbarState): boolean => state.display,
    message: (state: SnackbarState): string => state.message,
    type: (state: SnackbarState): SnackbarType => state.type
  },
  actions: {
    async displaySnackbar(
      {commit}: ActionContext<SnackbarState, SnackbarState>,
      payload: DisplaySnackbarPayload
    ): Promise<void> {
      commit('DISPLAY_SNACKBAR', payload);
      setTimeout(() => commit('HIDE_SNACKBAR'), AppConfig.snackbarTimeout);
    },

    async hideSnackbar(
      {commit}: ActionContext<SnackbarState, SnackbarState>
    ): Promise<void> {
      commit('HIDE_SNACKBAR');
    }
  },
  mutations: {
    DISPLAY_SNACKBAR(state: SnackbarState, payload: DisplaySnackbarPayload) {
      state.display = true;
      state.message = payload.message;
      state.type = payload.type;
    },

    HIDE_SNACKBAR(state: SnackbarState) {
      state.display = false;
      state.message = '';
      state.type = SnackbarType.INFO;
    }
  }
}
