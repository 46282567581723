
  import {defineComponent} from 'vue';
  import UserPortalLabel from "@/components/common/label/UserPortalLabel.vue";
  import OrganisationList from "@/components/organisation/selection/OrganisationList.vue";
  import PermissionUtils from "@/utils/PermissionUtils";

  export default defineComponent({
    name: "OrganisationSelection",
    data() {
      return {
        displayDropdown: false,
      }
    },
    components: {
      UserPortalLabel,
      OrganisationList,
    },
    methods: {
      isAllowedToViewOrganizations(): boolean {
        return PermissionUtils.isAllowedToViewOrganizations();
      },
      toggleDisplay(): void {
        this.displayDropdown = !this.displayDropdown;
      },
      navigateToCreateOrganisation(): void {
        this.$router.push('organisationManagement');
      },
    }
  });
