
  import {defineComponent} from 'vue';
  import UserPortalLabel from "@/components/common/label/UserPortalLabel.vue";

  export default defineComponent({
    name: '3',
    components: {
      UserPortalLabel
    },
    props: {
      title: {
        required: false,
        type: String,
      },
      options: {
        required: true,
        type: Array
      },
      selectedOptions: {
        required: true,
        type: Array
      },
      getOptionValue: {
        required: true,
        type: Function
      },
      getOptionName: {
        required: true,
        type: Function
      },
      isMandatory: {
        required: false,
        type: Boolean,
        default: false
      }
    },
    computed: {
      columns(): Array<any> {
        const columns = [];
        const mid = Math.ceil(this.options.length / 4);
        for (let col = 0; col < 4; col++) {
          columns.push(this.options.slice(col * mid, col * mid + mid))
        }
        return columns
      }
    },
    methods: {
      hasTitle(): boolean {
        return !!this.title;
      },
      isOptionSelected(label: unknown): boolean {
        return this.selectedOptions.find(l => this.areOptionsEqual(l, label)) !== undefined;
      },
      areOptionsEqual(a: unknown, b: unknown): boolean {
        return this.getOptionValue(a) === this.getOptionValue(b);
      },
      updateSelectedOptions(clickedOption: unknown): void {
        let updatedSelectedOptions;
        if (this.isOptionSelected(clickedOption)) {
          updatedSelectedOptions = this.removeOptionFromSelectedList(clickedOption);
        } else {
          updatedSelectedOptions = this.addOptionToSelectedList(clickedOption);
        }
        this.$emit("update:selectedOptions", updatedSelectedOptions);
      },
      addOptionToSelectedList(option: unknown): Array<unknown> {
        return [...this.selectedOptions, option];
      },
      removeOptionFromSelectedList(option: unknown): Array<unknown> {
        const updatedSelectedOptions = [...this.selectedOptions];
        return updatedSelectedOptions.filter(selectedOption => !this.areOptionsEqual(selectedOption, option));
      },
    }
  })
