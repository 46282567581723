
import {defineComponent} from 'vue';
import User from '@/model/User';
import OrganisationWithUser from '@/model/OrganisationWithUser';
import UserPortalInput from "@/components/common/input/UserPortalInput.vue";
import UserPortalDropdown from "@/components/common/dropdown/UserPortalDropdown.vue";
import UserPortalButton from "@/components/common/button/UserPortalButton.vue";
import UserPortalList from "@/components/common/listWithSwitch/UserPortalList.vue";
import Organisation from "@/model/Organisation";
import SnackbarType from "@/constants/SnackbarType";
import LoggerUtils from "@/utils/LoggerUtils";
import PermissionUtils from "@/utils/PermissionUtils";
import DialogUtils from "@/utils/DialogUtils";
import UserForm from "@/components/user/form/UserForm.vue";
import FeatureTypeConverter from "@/utils/FeatureTypeConverter";
import FeatureRightUtils from "@/utils/FeatureRightUtils";
import UserPortalLabel from "@/components/common/label/UserPortalLabel.vue";
import {FeatureTypes} from "@/constants/FeatureTypes";
import {FeatureRight} from "@/constants/FeatureRight";

const VALID_ORGANISATION_NAME_REGEX = '^[a-z0-9\\-]+$';

export default defineComponent({
  name: "OrganisationForm",
  components: {
    UserPortalInput,
    UserPortalDropdown,
    UserPortalList,
    UserPortalButton,
    UserForm,
    UserPortalLabel,
  },
  data() {
    return {
      originalOrganisation: this.edit && this.editedOrganisationWithUser.organisation ?
        this.editedOrganisationWithUser.organisation.copy() : Organisation.empty(),
      performingApiAccessOperation: false as boolean,
      selectedPartner: "" as string,
      organisation: this.edit && this.editedOrganisationWithUser.organisation ?
        this.editedOrganisationWithUser.organisation : Organisation.empty() as Organisation,
      user: this.edit && this.editedOrganisationWithUser.user ?
        this.editedOrganisationWithUser.user : User.empty() as User,
      userFormInvalid: true as boolean,
      isOrganisationChanged: false as boolean,
      isUserChanged: false as boolean,
      selectedOrgFeature: null as FeatureTypes | null,
    }
  },
  props: {
    edit: {
      required: true,
      type: Boolean
    },
    editedOrganisationWithUser: {
      required: false,
      type: OrganisationWithUser,
      default: {} as OrganisationWithUser
    }
  },
  watch: {
    editedOrganisationWithUser: {
      immediate: true,
      handler(value: OrganisationWithUser) {
        if (this.edit && value.organisation !== undefined) {
          this.organisation = value.organisation;
          this.user = value.user;
          this.originalOrganisation = value.organisation.copy();
        }
      }
    },
    organisation: {
      immediate: true,
      deep: true,
      handler(value: Organisation) {
        if (this.edit) {
          const isOrganizationNameChanged: boolean = (value.organisationName || "") !== (this.originalOrganisation.organisationName || "");
          const servicePartnerChanged: boolean = value.notMatchesServicePartners(this.originalOrganisation);
          const organisationFeaturesChanged: boolean = value.notMatchesOrganisationFeatures(this.originalOrganisation);
          const smartSisEnabledChanged: boolean = value.smartSisEnabledChanged(this.originalOrganisation);
          const geoserverLayerCatalogApisAccessChanged: boolean = value.geoserverLayerCatalogApisAccessChanged(this.originalOrganisation);
          this.isOrganisationChanged = isOrganizationNameChanged || servicePartnerChanged ||
            organisationFeaturesChanged || smartSisEnabledChanged || geoserverLayerCatalogApisAccessChanged;
        }
      }
    },
  },
  computed: {
    selectedFeatures: {
      get(): Array<FeatureTypes> {
        return (this.edit && this.editedOrganisationWithUser.organisation) ?
          this.editedOrganisationWithUser.organisation.organisationFeatures
            .map(feature => FeatureTypeConverter.getTypeForFeature(feature))
            .filter(feature => feature !== null) :
          this.organisation.organisationFeatures
            .map(feature => FeatureTypeConverter.getTypeForFeature(feature))
            .filter(feature => feature !== null);
      },
      set(selectedFeatures: Array<FeatureTypes>) {
        const selectedFeatureNames: Array<any> = selectedFeatures.map(f => f.name);
        if (this.edit) {
          this.editedOrganisationWithUser.organisation.setFeatures(selectedFeatureNames);
        } else {
          this.organisation.setFeatures(selectedFeatureNames);
        }
      }
    },
    selectedFeatureRights: {
      get(): Array<FeatureRight> {
        return (this.edit && this.editedOrganisationWithUser.organisation) ?
          this.editedOrganisationWithUser.organisation.organisationFeatures
            .map(feature => FeatureRightUtils.getFeatureRightByName(feature))
            .filter(featureRight => featureRight !== null) :
          this.organisation.organisationFeatures
            .map(feature => FeatureRightUtils.getFeatureRightByName(feature))
            .filter(featureRight => featureRight !== null);
      },
      set(selectedFeatureRights: Array<FeatureRight>) {
        const selectedFeatureNamesAndRights: Array<any> =
          FeatureRightUtils.getFeatureRightNames(selectedFeatureRights);
        if (this.edit) {
          this.editedOrganisationWithUser.organisation.setFeatures(selectedFeatureNamesAndRights);
        } else {
          this.organisation.setFeatures(selectedFeatureNamesAndRights);
        }
      }
    },
    selectedSharedFeatures: {
      get(): Array<any> {
        return (this.edit && this.editedOrganisationWithUser.organisation) ?
          this.editedOrganisationWithUser.organisation.getSharedFeatures(this.selectedPartner)
            .filter(sf => this.selectedFeatures.includes(sf)) :
          this.organisation.getSharedFeatures(this.selectedPartner)
            .filter(sf => this.selectedFeatures.includes(sf));
      },
      set(sharedFeatures: Array<any>) {
        if (this.selectedPartner) {
          const removedFeatureServices =
            this.selectedSharedFeatures.filter(ssf => !sharedFeatures.includes(ssf)).map(ssf => ssf.belongsToService);
          const newSharedFeatures = sharedFeatures.filter(sf => !removedFeatureServices.includes(sf.belongsToService));
          if (this.edit) {
            this.editedOrganisationWithUser.organisation.setSharedFeatures(this.selectedPartner, newSharedFeatures);
          } else {
            this.organisation.setSharedFeatures(this.selectedPartner, newSharedFeatures);
          }
        }
      }
    },
    availablePartnerIds(): Array<string> {
      return this.$store.direct.getters.organisationModule.allOrganisationIds ?
        [...this.$store.direct.getters.organisationModule.allOrganisationIds].filter(pId => pId !== this.organisation.organisationId) : [];
    },
    availableServices(): Array<object> {
      return this.$store.direct.getters.organisationModule.allServices ?
        [...this.$store.direct.getters.organisationModule.allServices] : [];
    },
    availableFeatures(): Array<FeatureTypes> {
      return this.$store.direct.getters.organisationModule.allFeatures ?
        [...this.$store.direct.getters.organisationModule.allFeatures] : [];
    },
    availableRightsForSelectedFeature(): Array<FeatureRight> {
      return this.selectedOrgFeature === null ?
        [] : FeatureRightUtils.getRightsForFeature(this.selectedOrgFeature.name);
    },
    getSaveButtonTitle(): string {
      return this.edit ? 'Save changes' : 'Save new organization';
    },
    isOrganisationIdValid(): boolean {
      const matched = this.organisation?.organisationId?.match(VALID_ORGANISATION_NAME_REGEX);
      return this.organisation?.organisationId?.length > 0 && matched !== null && matched?.length > 0
        && this.getValidationMessageForID === '';
    },
    hasAnyInvalidFields(): boolean {
      return !this.isOrganisationIdValid || this.userFormInvalid;
    },
    isAllowedToEditOrganization(): boolean {
      return !this.performingApiAccessOperation && PermissionUtils.isAllowedToEditOrganization();
    },
    getValidationMessageForID(): string {
      if (this.organisation?.organisationId && !this.organisation?.organisationId?.match(VALID_ORGANISATION_NAME_REGEX)) {
        return 'Only lower case letters, dashes (-) and numbers are allowed for Organization Name!';
      }
      const currentOrganisationId = this.organisation?.organisationId;
      const organisationIds = this.$store.direct.getters.organisationModule.allOrganisationIds;
      const isNotEditOrganisationMode = !this.edit;
      const isAnUsedOrganisationName = organisationIds && organisationIds.includes(currentOrganisationId);
      if (isAnUsedOrganisationName && isNotEditOrganisationMode) {
        return 'Organization Name is already used!'
      }
      return '';
    },
    organisationWasNotChanged(): boolean {
      return this.edit ? !this.isOrganisationChanged && !this.isUserChanged : false;
    },
    editUser(): boolean {
      return this.edit && (this.$store.direct.getters.organisationModule.selectedOrganisationHasUser || false);
    },
    hasNoServicesConfigured(): boolean {
      return this.organisation?.servicePartners.size === 0;
    }
  },
  methods: {
    isSmartSisEnabled(): boolean {
      return this.organisation.smartSisEnabled;
    },
    geoserverLayerCatalogApisAccessEnabled(): boolean {
      return this.organisation.geoserverLayerCatalogApisAccessEnabled;
    },
    setSmartSisEnabled(): void {
      this.organisation.smartSisEnabled = !this.organisation.smartSisEnabled;
    },
    setGeoserverLayerCatalogApisAccessEnabled(): void {
      this.organisation.geoserverLayerCatalogApisAccessEnabled = !this.organisation.geoserverLayerCatalogApisAccessEnabled;
    },
    getDisplayValue(option: any): string {
      return option?.displayValue;
    },
    getNameValue(option: any): string {
      return option?.name;
    },
    userFormValidityChanged(isInvalid: boolean): void {
      this.userFormInvalid = isInvalid;
    },
    userDataChanged(userChanged: boolean): void {
      this.isUserChanged = userChanged;
    },
    deleteSelectedOrganizationWithConfirmation() {
      DialogUtils.confirmOperation({
        message: `Are you sure you want to delete the organization with ID: "${this.editedOrganisationWithUser.organisation.organisationId}"?`,
        yesAction: () => this.deleteSelectedOrganisation(),
        noAction: () => true
      })
    },
    async deleteSelectedOrganisation(): Promise<void> {
      try {
        this.performingApiAccessOperation = true;
        await this.$store.direct.dispatch.organisationModule.deleteOrganisation(this.editedOrganisationWithUser.organisation.organisationId);
        await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
          message: `Organization was deleted successfully!`,
          type: SnackbarType.SUCCESS
        });
        this.$router.push('/');
      } catch (e) {
        await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
          message: `Failed to delete organization.`,
          type: SnackbarType.ERROR
        });
        LoggerUtils.error(`Failed to delete organization. Reason: ${e.message}`);
      } finally {
        this.performingApiAccessOperation = false;
      }
    },
    async saveCurrentOrganisation(): Promise<void> {
      this.user.organisationId = this.organisation.organisationId;
      const organisationWithUser: OrganisationWithUser = {
        user: this.user,
        organisation: this.constructOrganisation()
      };
      try {
        this.performingApiAccessOperation = true;
        await this.$store.direct.dispatch.organisationModule.saveOrganisationWithUser(organisationWithUser);
        await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
          message: this.edit ?
            `Organization modifications were saved successfully!` :
            `Organization was created successfully!`,
          type: SnackbarType.SUCCESS
        });
        this.$router.push('/');
      } catch (e) {
        const jsonErrorResponse: any = await e.response.json();
        await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
          message: jsonErrorResponse.errorMessage,
          type: SnackbarType.ERROR
        });
        LoggerUtils.error(`Failed to create organization. Reason: ${jsonErrorResponse.errorMessage}`);
      } finally {
        this.performingApiAccessOperation = false;
      }
    },
    constructOrganisation(): Organisation {
      const organisationName: string | undefined = !this.organisation.organisationName ? undefined : this.organisation.organisationName;
      return new Organisation(
        this.organisation.organisationId,
        this.organisation.servicePartners,
        this.organisation.organisationFeatures,
        this.organisation.additionalClientRoles,
        this.organisation.smartSisEnabled,
        this.organisation.geoserverLayerCatalogApisAccessEnabled,
        organisationName
      );
    },
    hasConnectedServicesWithPartner(partnerName: string) {
      return this.organisation.sharesFeaturesWithPartner(partnerName);
    },
    isFeatureSelected(feature: FeatureTypes) {
      return this.selectedFeatures.find(sf => sf.name === feature.name) !== undefined;
    },
    checkOptionIsDisabled(option: any) {
      return option.prerequisiteRight === null ?
        false : this.selectedFeatureRights.find(sf => sf.name === option.prerequisiteRight) === undefined;
    }
  }
});
