
  import {defineComponent} from 'vue';
  import UserForm from "@/components/user/form/UserForm.vue";
  import User from "@/model/User";

  export default defineComponent({
    name: "CreateUserView",
    components: {
      UserForm
    },
    props: {
      user: {
        required: true,
        type: User
      }
    },
  });
