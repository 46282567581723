export default class ApiEndpoints {
  private static API_PATH = 'api';

  public static ORGANISATION = `${ApiEndpoints.API_PATH}/organisation`;
  public static ORGANISATION_WITH_USER = `${ApiEndpoints.API_PATH}/organisation-with-user`;
  public static ORGANISATION_IDS = `${ApiEndpoints.API_PATH}/organisation-ids`;
  public static ORGANISATION_SECRET = `${ApiEndpoints.API_PATH}/organisation/key`;
  public static USER = `${ApiEndpoints.API_PATH}/user`;
  public static USER_PASSWORD = `${ApiEndpoints.API_PATH}/user-password`;
  public static USERS_IN_ORGANIZATION = `${ApiEndpoints.API_PATH}/user-in-organisation`;
  public static SERVICES = `${ApiEndpoints.API_PATH}/services`;
  public static FEATURES = `${ApiEndpoints.API_PATH}/featureRoles`;
  public static GROUPS2USERS = `${ApiEndpoints.API_PATH}/setGroups2User`;
  public static GROUPS4USERS = `${ApiEndpoints.API_PATH}/groups4User`;
  public static GROUPS = `${ApiEndpoints.API_PATH}/groups`;
  public static DOCUMENTATION = `${ApiEndpoints.API_PATH}/documentation`;
}