import { createDirectStore } from "direct-vuex"
import loginModule from './modules/login';
import organisationModule from './modules/organisation';
import snackbarModule from './modules/snackbar';
import userModule from './modules/user';
import dialogModule from './modules/dialog';

const { store, rootActionContext, moduleActionContext } = createDirectStore({
  modules: {
    loginModule,
    userModule,
    organisationModule,
    snackbarModule,
    dialogModule
  }
});

export default store;
export { rootActionContext, moduleActionContext };

export type AppStore = typeof store;
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}
