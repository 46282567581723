import Organisation from "@/model/Organisation";
import OrganisationApi from "@/api/OrganisationApi";
import {ActionContext} from "vuex";
import ServicesApi from "@/api/ServicesApi";
import OrganisationWithUser from "@/model/OrganisationWithUser";
import ServiceTypeConverter from "@/utils/ServiceTypeConverter";
import ModelUtils from "@/utils/ModelUtils";
import FeaturesApi from "@/api/FeaturesApi";
import FeatureTypeConverter from "@/utils/FeatureTypeConverter";
import {FeatureTypes} from "@/constants/FeatureTypes";

export interface OrganisationsState {
  allOrganisationIds: Array<string> | undefined;
  selectedOrganisationWithUser: OrganisationWithUser | undefined;
  selectedOrganisationHasUser: boolean | undefined;
  services: Array<string> | undefined;
  features: Array<string> | undefined;
}

export default {
  namespaced: true as true,
  state: {
    allOrganisationIds: undefined,
    selectedOrganisationWithUser: undefined,
    selectedOrganisationHasUser: undefined,
    services: undefined,
    features: undefined,
  } as OrganisationsState,
  getters: {
    allOrganisationIds: (state: OrganisationsState): Array<string> | undefined => state.allOrganisationIds,
    selectedOrganisationWithUser: (state: OrganisationsState): OrganisationWithUser | undefined => state.selectedOrganisationWithUser,
    selectedOrganisationHasUser: (state: OrganisationsState): boolean | undefined => state.selectedOrganisationHasUser,
    allServices: (state: OrganisationsState): Array<object> | undefined =>
      state.services?.map(service => ServiceTypeConverter.getTypeFroService(service)),
    allFeatures: (state: OrganisationsState): Array<FeatureTypes> | undefined =>
      state.features?.map(feature => FeatureTypeConverter.getTypeForFeature(feature)).filter(feature => feature),
  },
  actions: {
    async deleteOrganisation(
      {commit}: ActionContext<OrganisationsState, OrganisationsState>,
      deletedOrganisationId: string
    ): Promise<void> {
      try {
        await OrganisationApi.deleteOrganisation(deletedOrganisationId);
        commit('DELETE_ORGANISATION', deletedOrganisationId);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async saveOrganisationWithUser(
      {commit}: ActionContext<OrganisationsState, OrganisationsState>,
      savedOrganisationWithUser: OrganisationWithUser
    ): Promise<void> {
      try {
        await OrganisationApi.saveOrganisationWithUser(savedOrganisationWithUser);
        commit('SAVE_ORGANISATION', savedOrganisationWithUser.organisation);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchAllOrganisationIds(
      {commit}: ActionContext<OrganisationsState, OrganisationsState>
    ): Promise<void> {
      try {
        const organisationIds: Array<string> = await OrganisationApi.getOrganisationIds();
        commit('UPDATE_ORGANISATION_IDS', organisationIds);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchSelectedOrganisationDetails(
      {commit, dispatch}: ActionContext<OrganisationsState, OrganisationsState>,
      selectedOrganisationId: string
    ): Promise<void> {
      try {
        const selectedOrganisationWithUser: OrganisationWithUser = await OrganisationApi.getOrganisationWithUser(selectedOrganisationId);
        commit('UPDATE_SELECTED_ORGANISATION', selectedOrganisationWithUser);
        commit('UPDATE_ORIGINAL_ORGANISATION_USER', selectedOrganisationWithUser);
        await dispatch('userModule/fetchAllUsersInOrganization', null, {root: true});
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchAllServices(
      {commit}: ActionContext<OrganisationsState, OrganisationsState>
    ): Promise<void> {
      try {
        const services: Array<string> = await ServicesApi.getAllServices();
        commit('UPDATE_SERVICES', services);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchAllFeatures(
      {commit}: ActionContext<OrganisationsState, OrganisationsState>
    ): Promise<void> {
      try {
        const features: Array<string> = await FeaturesApi.getAllFeatures();
        commit('UPDATE_FEATURES', features);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  mutations: {
    DELETE_ORGANISATION(state: OrganisationsState, deletedOrganisationId: string): void {
      state.allOrganisationIds =
        state.allOrganisationIds?.filter(currentOrganisationId => currentOrganisationId !== deletedOrganisationId);
      if (state.selectedOrganisationWithUser?.organisation?.organisationId === deletedOrganisationId) {
        state.selectedOrganisationWithUser = undefined;
      }
    },

    SAVE_ORGANISATION(state: OrganisationsState, savedOrganisation: Organisation): void {
      const savedOrganisationId: string = savedOrganisation.organisationId;
      const savedOrganisationIndex: number | undefined = state.allOrganisationIds?.findIndex(
        currentOrganisationId => currentOrganisationId === savedOrganisationId
      );
      if (savedOrganisationIndex === -1) {
        state.allOrganisationIds?.push(savedOrganisationId);
        state.allOrganisationIds?.sort();
      }
      state.selectedOrganisationWithUser = undefined;
    },

    UPDATE_ORGANISATION_IDS(state: OrganisationsState, organisationIds: Array<string>): void {
      state.allOrganisationIds = [...organisationIds];
      state.allOrganisationIds.sort((org1, org2) => ModelUtils.sort(org1, org2));
    },

    UPDATE_SELECTED_ORGANISATION(state: OrganisationsState, newSelectedOrganisationWithUser: OrganisationWithUser): void {
      state.selectedOrganisationWithUser = newSelectedOrganisationWithUser;
    },

    UPDATE_ORIGINAL_ORGANISATION_USER(state: OrganisationsState, newSelectedOrganisationWithUser: OrganisationWithUser): void {
      state.selectedOrganisationHasUser = !newSelectedOrganisationWithUser.user.isEmptyUser();
    },

    UPDATE_SERVICES(state: OrganisationsState, services: Array<string>): void {
      state.services = services;
    },

    UPDATE_FEATURES(state: OrganisationsState, features: Array<string>): void {
      state.features = features;
    }
  }
}
