import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import UserManagement from "@/components/user/UserManagement.vue";
import EditUserView from "@/components/user/EditUserView.vue";
import CreateUserView from "@/components/user/CreateUserView.vue";
import Documentation from "@/components/header/nav/Documentation.vue";
import Home from "@/components/header/nav/Home.vue";
import OrganisationManagement from "@/components/organisation/OrganisationManagement.vue";
import OrganisationApiAccessView from "@/components/organization-api-access/OrganisationApiAccessView.vue";
import User from "@/model/User";
import Store from "@/store";
import PermissionUtils from "@/utils/PermissionUtils";

function getUserFromRoute (route: any) {
  if(route.query.username){
    return {
      user: Store.getters.userModule.getUser(route.query.username)
    }
  }
  return {
    user:  User.empty()
  }
}

function getEditStateFromRoute(route: any) {
  return {
    editSelectedOrganisation: route.query.editSelectedOrganisation !== undefined
  };
}

function getCreateUserProps() {
  return {
    user: User.empty()
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation
  },
  {
    path: '/accessManagement',
    name: 'AccessManagement',
    component: UserManagement
  },
  {
    path: '/organisationManagement',
    name: 'OrganisationManagement',
    component: OrganisationManagement,
    props: getEditStateFromRoute
  },
  {
    path: '/createUser',
    name: 'CreateUser',
    component: CreateUserView,
    props: getCreateUserProps
  },
  {
    path: '/editUser',
    name: 'EditUser',
    component: EditUserView,
    props: getUserFromRoute
  },
  {
    path: '/organisationApiAccess',
    name: 'OrganisationApiAccess',
    component: OrganisationApiAccessView,
    beforeEnter: (to, from, next) => {
      if (PermissionUtils.isAllowedToManageOrganizationSecret()) {
        next();
      }
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
