import {ActionContext} from 'vuex';
import {KeycloakInstance} from 'keycloak-js';

export interface LoginState {
  keycloak: KeycloakInstance | null;
  providerId: string | null;
}

export default {
  namespaced: true as true,
  state: {
    keycloak: null,
    providerId: null,
  } as LoginState,
  mutations: {
    UPDATE_TOKEN(state: LoginState, keycloak: KeycloakInstance) {
      state.keycloak = keycloak
    },
    LOGOUT(state: LoginState) {
      state.keycloak?.logout();
    },
    UPDATE_USER_PROFILE(state: LoginState, providerId: string) {
      state.providerId = providerId;
    },
  },
  actions: {
    async updateToken({commit}: ActionContext<LoginState, LoginState>, keycloak: KeycloakInstance) {
      commit("UPDATE_TOKEN", keycloak)
    },
    async logout({commit}: ActionContext<LoginState, LoginState>) {
      commit("LOGOUT")
    },
    async updateUserProfile({commit}: ActionContext<LoginState, LoginState>, providerId: string) {
      commit("UPDATE_USER_PROFILE", providerId);
    },
  },
  getters: {
    token: (state: LoginState): string | undefined => state.keycloak?.token,
    username: (state: LoginState): string | undefined => (state.keycloak?.idTokenParsed as any)?.preferred_username,
    providerId: (state: LoginState): string | undefined => state.providerId ? state.providerId : undefined,
  }
}