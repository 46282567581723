import BaseApi from "@/api/BaseApi";
import ApiEndpoints from "@/constants/apiEndpoints";

class ServicesApi extends BaseApi {
  public async getAllServices(): Promise<Array<string>> {
    const apiResponse: Response = await this.get(ApiEndpoints.SERVICES);
    return await apiResponse.json();
  }
}

export default new ServicesApi();
