
  import {defineComponent} from 'vue';
  import UserPortalInput from "@/components/common/input/UserPortalInput.vue";
  import UserPortalButton from "@/components/common/button/UserPortalButton.vue";
  import SnackbarType from "@/constants/SnackbarType";
  import LoggerUtils from "@/utils/LoggerUtils";
  import DialogUtils from "@/utils/DialogUtils";
  import OrganisationApi from "@/api/OrganisationApi";

  export default defineComponent({
    name: "OrganisationApiAccessView",
    components: {
      UserPortalInput,
      UserPortalButton
    },
    data: () => {
      return {
        secret: '',
        loadingSecret: false,
      }
    },
    async mounted() {
      this.loadingSecret = true;
      try {
        if (this.organisationId) {
          this.secret = await OrganisationApi.getOrganisationSecret(this.organisationId);
        } else {
          await this.onError(`No organization is selected. Cannot fetch secret.`);
        }
      } catch (e) {
        await this.onError(`Failed to fetch the secret for organization [${this.organisationId}].`, e);
      } finally {
        this.loadingSecret = false;
      }
    },
    computed: {
      organisationId(): string | undefined {
        return this.$store.direct.getters.organisationModule.selectedOrganisationWithUser?.organisation?.organisationId;
      }
    },
    methods: {
      async copySecret(): Promise<void> {
        const secretInput: HTMLInputElement | null = document.querySelector('#secret-readonly');
        if (secretInput) {
          secretInput.removeAttribute('disabled');
          secretInput.select();
          try {
            document.execCommand('copy') ?
              await this.onSuccess('Secret copied successfully.') :
              await this.onError('Could not copy secret.', 'HTML document "copy" command failed.');
            secretInput.setSelectionRange(0, 0);
            secretInput.setAttribute('disabled', '');
          } catch (e) {
            await this.onError('Could not copy secret.', e);
          }
        } else {
          await this.onError('Could not copy secret.', 'Could not find element with id [#secret-readonly].');
        }
      },
      resetSecretWithConfirmation(): void {
        DialogUtils.confirmOperation({
          message: `Are you sure you want to reset the secret for organization [${this.organisationId}]?`,
          yesAction: async () => await this.resetSecret(),
          noAction: () =>
            LoggerUtils.info(`Secret reset operation for organization [${this.organisationId}] was dropped.`)
        });
      },
      async resetSecret(): Promise<void> {
        this.loadingSecret = true;
        try {
          if (this.organisationId) {
            this.secret = await OrganisationApi.resetOrganisationSecret(this.organisationId);
          } else {
            await this.onError(`No organization is selected. Cannot fetch secret.`);
          }
        } catch (e) {
          await this.onError(`Failed to reset the secret for organization [${this.organisationId}].`, e);
        } finally {
          this.loadingSecret = false;
        }
      },
      async onSuccess(message: string): Promise<void> {
        await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
          message: message,
          type: SnackbarType.SUCCESS
        });
      },
      async onError(message: string, reason?: Error | string): Promise<void> {
        await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
          message: message,
          type: SnackbarType.ERROR
        });
        LoggerUtils.error(`${message} Reason: ${reason}`);
      }
    }
  });
