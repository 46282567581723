import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "userPortalSelect" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPortalLabel = _resolveComponent("UserPortalLabel")

  return (_openBlock(), _createBlock("div", {
    id: this.name
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_UserPortalLabel, {
        content: this.title,
        "is-form-control-label": true,
        "is-mandatory-field-label": this.isMandatory
      }, null, 8, ["content", "is-mandatory-field-label"]),
      _createVNode("div", {
        class: {
        userPortalSelectList: true,
          },
        style: `max-height: ${this.maxHeight}px`
      }, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(this.options, (option) => {
          return (_openBlock(), _createBlock("div", {
            id: `${this.name}Option${option}`,
            key: `${this.name}Option${option}`,
            class: "userPortalListRow"
          }, [
            _createVNode("div", {
              class: {
              userPortalDropdownItem: true,
              userPortalListItem: true,
              selected: this.isOptionSelected(option),
              highlighted: this.checkIfOptionIsHighlighted(option)
            },
              onClick: ($event: any) => (this.updateSelectedOption(option))
            }, _toDisplayString(this.getOptionDisplayedValue(option)), 11, ["onClick"])
          ], 8, ["id"]))
        }), 128))
      ], 4)
    ])
  ], 8, ["id"]))
}