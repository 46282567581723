import BaseApi from "@/api/BaseApi";
import ApiEndpoints from "@/constants/apiEndpoints";

class DocumentationApi extends BaseApi {
  public async getPdfDocumentation(): Promise<Blob> {
    const apiResponse: Response = await this.get(ApiEndpoints.DOCUMENTATION);
    return await apiResponse.blob();
  }
}

export default new DocumentationApi();
