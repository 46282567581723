import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: {
        unselectable: true,
        switch: true,
        enabled: _ctx.enabled,
        disabled: !_ctx.enabled
      },
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (this.toggle && this.toggle(...args)))
  }, _toDisplayString(_ctx.enabled ? 'Enabled' : 'Disabled'), 3))
}