
  import { defineComponent } from 'vue';
  import DialogUtils from "@/utils/DialogUtils";

  export default defineComponent({
    name: 'NavigationBar',
    methods: {
      logoutWithConfirmation(): void {
        DialogUtils.confirmOperation({
          message: 'You will be logged out. Do you wish to proceed?',
          yesAction: () => this.logout(),
          noAction: () => true
        })
      },
      logout(): void {
        this.$store.direct.dispatch.loginModule.logout();
      }
    }
  });
