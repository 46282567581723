
  import {defineComponent} from 'vue';
  import UserPortalLabel from "@/components/common/label/UserPortalLabel.vue";

  export default defineComponent({
    name: "UserPortalInput",
    components: {
      UserPortalLabel
    },
    props: {
      name: {
        required: true,
        type: String
      },
      title: {
        required: true,
        type: String
      },
      type: {
        required: false,
        type: String,
        default: "text"
      },
      isMandatory: {
        required: true,
        type: Boolean
      },
      isValid: {
        required: true,
        type: Boolean
      },
      value: {
        required: true,
        type: String
      },
      disabled: {
        required: false,
        default: false,
        type: Boolean
      },
      validationMessage: {
        required: false,
        type: String
      }
    },
    computed: {
      internalValue: {
        get(): string {
          return this.value;
        },
        set(newValue: string): void {
          this.$emit('update:value', newValue);
        }
      },
      hasValidationMessage(): boolean {
        return this.validationMessage !== '';
      }
    }
  });
