export interface LogLevel {
  order: number;
  name: string;
}

const logLevels: { [key: string]: LogLevel } = {}
logLevels['all'] = {
  order: 0,
  name: 'ALL'
}
logLevels['debug'] = {
  order: 1,
  name: 'DEBUG'
}
logLevels['info'] = {
  order: 2,
  name: 'INFO'
}
logLevels['warn'] = {
  order: 3,
  name: 'WARN'
}
logLevels['error'] = {
  order: 4,
  name: 'ERROR'
}
logLevels['fatal'] = {
  order: 5,
  name: 'FATAL'
}
export default logLevels;