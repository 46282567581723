
  import {defineComponent} from 'vue';

  enum ButtonType {
    BUTTON = "button",
    SUBMIT = "submit",
    RESET = "reset"
  }

  export default defineComponent({
    name: "UserPortalButton",
    props: {
      title: {
        required: true as true,
        type: String
      },
      type: {
        type: String as () => ButtonType,
        required: false as false,
        default: "submit" as ButtonType.SUBMIT
      },
      triggersCriticalOperation: {
        required: false as false,
        default: false as false,
        type: Boolean
      },
      disabled: {
        required: false,
        default: false,
        type: Boolean
      }
    }
  });
