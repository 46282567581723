import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-edit-view" }
const _hoisted_2 = { class: "user-edit-view-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPortalLabel = _resolveComponent("UserPortalLabel")
  const _component_UserForm = _resolveComponent("UserForm")
  const _component_ResetPasswordForm = _resolveComponent("ResetPasswordForm")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_UserPortalLabel, {
        content: `Editing User: ${_ctx.user.username}`
      }, null, 8, ["content"])
    ]),
    _createVNode("div", null, [
      _createVNode(_component_UserForm, {
        createUserAllowed: false,
        edit: true,
        user: _ctx.user
      }, null, 8, ["user"]),
      _createVNode(_component_ResetPasswordForm, {
        username: _ctx.user.username
      }, null, 8, ["username"])
    ])
  ]))
}