
  import {defineComponent} from 'vue';
  import UserPortalInput from "@/components/common/input/UserPortalInput.vue";
  import UserPortalLabel from "@/components/common/label/UserPortalLabel.vue";
  import UserPortalOptionList from "@/components/common/dropdown/UserPortalOptionList.vue";

  export default defineComponent({
    name: "UserPortalDropdown",
    components: {
      UserPortalInput,
      UserPortalLabel,
      UserPortalOptionList
    },
    created() {
      document.addEventListener('click', this.clicked);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.clicked);
    },
    data() {
      return {
        inputValue: '' as string,
        searchInputFocused: false as boolean,
        displayedOptions: this.options
      }
    },
    props: {
      name: {
        required: true,
        type: String
      },
      title: {
        required: true,
        type: String
      },
      isMandatory: {
        required: true,
        type: Boolean
      },
      options: {
        required: true,
        type: Array
      },
      getOptionDisplayedValue: {
        required: true,
        type: Function
      },
      getOptionIdentifierValue: {
        required: true,
        type: Function
      },
      getOptionSearchableValue: {
        required: true,
        type: Function
      },
      selectedOptions: {
        required: true,
        type: Array
      },
      enableMultipleSelection: {
        required: false,
        type: Boolean,
        default: false
      },
      searchable: {
        required: false,
        type: Boolean,
        default: false
      },
      maxHeight: {
        required: false,
        type: Number,
        default: 115
      },
      checkOptionIsDisabled: {
        required: true,
        type: Function
      }
    },
    watch: {
      options: {
        immediate: true,
        deep: true,
        handler(newValue: Array<object>) {
          this.displayedOptions = newValue;
        }
      }
    },
    methods: {
      updateSelectedOptions(clickedOption: unknown): void {
        let updatedSelectedOptions;
        if (this.isOptionSelected(clickedOption)) {
          updatedSelectedOptions = this.removeOptionFromSelectedList(clickedOption);
        } else {
          updatedSelectedOptions = this.addOptionToSelectedList(clickedOption);
        }
        this.$emit("update:selectedOptions", updatedSelectedOptions);
      },
      addOptionToSelectedList(option: unknown): Array<unknown> {
        let updatedSelectedOptions;
        if (this.searchable) {
          updatedSelectedOptions = [option];
          this.inputValue = this.getOptionDisplayedValue(option);
        } else {
          if (this.enableMultipleSelection) {
            updatedSelectedOptions = [...this.selectedOptions, option];
          } else {
            updatedSelectedOptions = [option];
          }
        }
        return updatedSelectedOptions;
      },
      removeOptionFromSelectedList(option: unknown): Array<unknown> {
        const updatedSelectedOptions = [...this.selectedOptions];
        return updatedSelectedOptions.filter(selectedOption => !this.areOptionsEqual(selectedOption, option));
      },
      containsSearchInput(currentOption: unknown): boolean {
        const currentOptionSearchableValue = this.getOptionSearchableValue(currentOption).toLowerCase();
        const currentSearchInput = this.inputValue.toLowerCase();
        return currentOptionSearchableValue.includes(currentSearchInput)
      },
      isOptionSelected(currentOption: unknown): boolean {
        return this.selectedOptions.find(selectedOption => this.areOptionsEqual(selectedOption, currentOption)) !== undefined;
      },
      areOptionsEqual(a: unknown, b: unknown): boolean {
        return this.getOptionIdentifierValue(a) === this.getOptionIdentifierValue(b);
      },
      clicked(event: MouseEvent): void {
        const clickedOnOptionList: boolean = (event.target as HTMLElement).id.startsWith(`${this.name}Option`);
        const clickedOnSearchInput: boolean = (event.target as HTMLElement).id === `${this.name}SearchInput`;
        this.searchInputFocused = clickedOnOptionList || clickedOnSearchInput;
      }
    }
  });
