
  import {defineComponent} from 'vue';
  import SnackbarType from "@/constants/SnackbarType";
  import LoggerUtils from "@/utils/LoggerUtils";
  import PermissionUtils from "@/utils/PermissionUtils";

  export default defineComponent({
    name: "OrganisationList",
    computed: {
      availableOrganisationIds(): readonly string[] {
        const organisations = this.$store.direct.getters.organisationModule.allOrganisationIds || [] as Array<string>;
        return PermissionUtils.isAllowedToViewOrganizations() ? organisations :
          organisations?.filter(o => o === this.$store.direct.getters.loginModule.providerId);
      },
      selectedOrganisationId(): string {
        return this.$store.direct.getters.organisationModule.selectedOrganisationWithUser?.organisation?.organisationId || '' as string;
      }
    },
    methods: {
      getOptionValue(option: string): string {
        return option;
      },
      async selectOrganisationId(selectedOrganisationId: string): Promise<void> {
        try {
          await this.$store.direct.dispatch.organisationModule.fetchSelectedOrganisationDetails(selectedOrganisationId);
          this.$router.push('organisationManagement?editSelectedOrganisation');
        } catch (e) {
          LoggerUtils.error(`Failed to fetch selected organization details. Reason: ${JSON.stringify(e)}`);
          await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
            message: 'Failed to fetch selected organization details.',
            type: SnackbarType.ERROR
          });
        }
      }
    }
  });
