export default class UserPassword {
  public username: string;
  public password: string;
  public isTemporary: boolean;

  constructor(username: string, password: string, isTemporary: boolean) {
    this.username = username;
    this.password = password;
    this.isTemporary = isTemporary;
  }
}