
  import {defineComponent} from 'vue';
  import UserPortalLabel from "@/components/common/label/UserPortalLabel.vue";
  import UserPortalSwitchButton from "@/components/common/switch-button/UserPortalSwitchButton.vue";

  export default defineComponent({
    name: 'UserPortalSwitchButtonContainer',
    components: {
      UserPortalLabel,
      UserPortalSwitchButton
    },
    props: {
      title: {
        required: true,
        type: String
      },
      enabled: {
        required: true,
        type: Boolean
      },
      enabledValue: {
        required: false,
        type: String,
        default: 'Enabled'
      },
      disabledValue: {
        required: false,
        type: String,
        default: 'Disabled'
      }
    },
    methods: {
      updateValue(value: boolean): void{
        this.$emit('update:enabled', value);
      },
    }
  })
