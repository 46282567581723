export default class User {
  public firstName: string;
  public lastName: string;
  public mail: string;
  public organisationId: string;
  public username: string;
  public password: string;

  constructor(
    firstName: string,
    lastName: string,
    mail: string,
    organisationId: string,
    username: string,
    password: string,
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.mail = mail;
    this.organisationId = organisationId;
    this.username = username;
    this.password = password;
  }

  public static empty(): User {
    return new User('', '', '', '', '', '');
  }

  public isEmptyUser(): boolean {
    return this.firstName == '' && this.lastName == '' && this.mail == '' && this.organisationId == ''
      && this.username == '' && this.password == '';
  }

  public setEditableProperties(updatedUser: User): void {
    this.firstName = updatedUser.firstName;
    this.lastName = updatedUser.lastName;
    this.mail = updatedUser.mail;
  }

}