import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: {
        userPortalSelectList: true,
        userPortalDropdownList: this.isDropdown
    },
    style: `max-height: ${this.maxHeight}px`
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(this.options, (option) => {
      return _withDirectives((_openBlock(), _createBlock("div", {
        id: `${this.name}Option${this.getOptionIdentifierValue(option)}`,
        onClick: ($event: any) => (this.updateSelectedOptions(option)),
        key: `${this.name}Option${this.getOptionIdentifierValue(option)}`,
        class: {
        userPortalDropdownItem: true,
        selected: this.isOptionSelected(option),
        'disabledOption': this.checkOptionIsDisabled(option)
      }
      }, _toDisplayString(this.getOptionDisplayedValue(option)), 11, ["id", "onClick"])), [
        [_vShow, this.containsSearchInput(option)]
      ])
    }), 128))
  ], 6))
}