import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "userPortalCheckBoxContainer" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPortalLabel = _resolveComponent("UserPortalLabel")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_UserPortalLabel, {
      content: this.title,
      "is-form-control-label": true,
      "is-mandatory": this.isMandatory
    }, null, 8, ["content", "is-mandatory"]), [
      [_vShow, this.hasTitle()]
    ]),
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
        return (_openBlock(), _createBlock("div", {
          key: column,
          class: "col"
        }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(column, (item) => {
            return (_openBlock(), _createBlock("div", {
              key: item,
              class: "item-container"
            }, [
              _createVNode("input", {
                checked: this.isOptionSelected(item),
                id: `${this.title}Option${this.getOptionValue(item)}`,
                value: this.getOptionValue(item.value),
                onClick: ($event: any) => (this.updateSelectedOptions(item)),
                class: "userPortalCheckBoxItem",
                type: "checkbox"
              }, null, 8, ["checked", "id", "value", "onClick"]),
              _createVNode(_component_UserPortalLabel, {
                content: this.getOptionName(item),
                "is-form-control-label": false,
                "is-mandatory": this.isMandatory
              }, null, 8, ["content", "is-mandatory"])
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}