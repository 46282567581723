import User from "@/model/User";

export default class OrganisationWithUser {
  public user: User;
  public userGroups: Array<string>;

  constructor(
    user: User,
    userGroups: Array<string>
  ) {
    this.user = user;
    this.userGroups = userGroups;
  }
}