
  import {defineComponent} from 'vue';
  import NavigationBar from "@/components/header/nav/NavigationBar.vue";

  export default defineComponent({
    name: 'Header',
    components: {
      NavigationBar
    }
  });
