import User from "@/model/User";

export default class UserProperties {
  public firstName: string;
  public lastName: string;
  public mail: string;
  public userGroups: Array<string>;
  public organisationId: string;

  constructor(user: User, userGroups: Array<string>) {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.mail = user.mail;
    this.userGroups = userGroups;
    this.organisationId = user.organisationId;
  }
}