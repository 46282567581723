
  import {defineComponent} from 'vue';
  import OrganisationForm from "@/components/organisation/form/OrganisationForm.vue";
  import OrganisationWithUser from '@/model/OrganisationWithUser';

  export default defineComponent({
    name: "OrganisationManagement",
    components: {
      OrganisationForm
    },
    props: {
      editSelectedOrganisation: {
        required: true,
        type: Boolean
      }
    },
    computed: {
      selectedOrganisationWithUser(): OrganisationWithUser | undefined {
        return this.$store.direct.getters.organisationModule.selectedOrganisationWithUser;
      }
    }
  });
