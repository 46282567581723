import Store from '../store';

export default class PermissionUtils{

  static isAllowedToViewOrganizations() {
    return Store.getters.userModule.isUtmAdmin;
  }

  static isAllowedToEditUser(): boolean {
    return Store.getters.userModule.isUtmAdmin || Store.getters.userModule.isOrganizationAdmin;
  }

  static isAllowedToManageGroups(): boolean {
    return Store.getters.userModule.isUtmAdmin || Store.getters.userModule.isOrganizationAdmin;
  }

  static isAllowedToEditOrganization(): boolean {
    return Store.getters.userModule.isUtmAdmin;
  }

  static isAllowedToManageOrganizationSecret(): boolean {
    return Store.getters.userModule.isUtmAdmin || Store.getters.userModule.isOrganizationAdmin;
  }

}