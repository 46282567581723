import Store from '../store';

export interface Query {
  asset: string;
  service: (...args: any[]) => any;
  arguments: any[];
  execute: boolean;
}

export default class AssetUtils {

  private static async loadData(queries: Array<Query>): Promise<void> {
    const queriesToExecute = queries.filter(query => query.execute);
    try {
      for (let i = 0; i < queriesToExecute.length; i++) {
        const query = queriesToExecute[i];
        await query.service(query.arguments);
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async loadAssets(): Promise<void> {
    return await AssetUtils.loadData([
      {
        asset: 'groups',
        service: (username: string) => {
          Store.dispatch.userModule.fetchAllGroupsForLoggedUser(username)
        },
        arguments: [Store.getters.loginModule.username],
        execute: true
      },
      {
        asset: 'organizations',
        service: () => Store.dispatch.organisationModule.fetchAllOrganisationIds(),
        arguments: [],
        execute: true
      },
      {
        asset: 'services',
        service: () => Store.dispatch.organisationModule.fetchAllServices(),
        arguments: [],
        execute: true
      },
      {
        asset: 'features',
        service: () => Store.dispatch.organisationModule.fetchAllFeatures(),
        arguments: [],
        execute: true
      }
    ]);
  }

}
