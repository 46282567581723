
  import {defineComponent} from 'vue';
  import UserPortalInput from "@/components/common/input/UserPortalInput.vue";
  import UserPortalButton from "@/components/common/button/UserPortalButton.vue";
  import UserPortalSwitchButtonContainer from "@/components/common/switch-button/UserPortalSwitchButtonContainer.vue";
  import UserPassword from "@/model/UserPassword";
  import UserApi from "@/api/UserApi";
  import SnackbarType from "@/constants/SnackbarType";
  import LoggerUtils from "@/utils/LoggerUtils";
  import DialogUtils from "@/utils/DialogUtils";

  export default defineComponent({
    name: "ResetPasswordForm",
    components: {
      UserPortalInput,
      UserPortalButton,
      UserPortalSwitchButtonContainer
    },
    props: {
      username: {
        required: true,
        type: String
      }
    },
    data: () => {
      return {
        password: '',
        passwordConfirmation: '',
        isTemporary: false
      }
    },
    methods: {
      isPasswordValid(): boolean {
        return this.password.length > 0;
      },
      isPasswordConfirmationValid(): boolean {
        return this.passwordConfirmation.length > 0 && this.passwordConfirmation === this.password;
      },
      hasAnyInvalidFields(): boolean {
        return !this.isPasswordValid() || !this.isPasswordConfirmationValid();
      },
      resetPasswordWithConfirmation(): void {
        DialogUtils.confirmOperation({
          message: `Are you sure you want to reset the password for user "${this.username}"?`,
          yesAction: async (): Promise<void> => {
            await this.resetPassword();
          },
          noAction: (): void => {
            LoggerUtils.info(`Password reset operation for user "${this.username}" was dropped.`);
          }
        });
      },
      async resetPassword(): Promise<void> {
        try {
          const userPassword: UserPassword = new UserPassword(this.username, this.password, this.isTemporary);
          await UserApi.resetUserPassword(userPassword);
          await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
            message: `Password for user "${this.username}" was changed.`,
            type: SnackbarType.SUCCESS
          });
          this.resetFieldValues();
        } catch (e) {
          await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
            message: `Failed to change password for user "${this.username}".`,
            type: SnackbarType.ERROR
          });
          LoggerUtils.error(`Failed to change password for user "${this.username}". Reason: ${e}`);
        }
      },
      resetFieldValues(): void {
        this.password = '';
        this.passwordConfirmation = '';
        this.isTemporary = false;
      }
    }
  });
