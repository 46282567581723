import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "userPortalSwitchButtonContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPortalLabel = _resolveComponent("UserPortalLabel")
  const _component_UserPortalSwitchButton = _resolveComponent("UserPortalSwitchButton")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_UserPortalLabel, {
      content: _ctx.title,
      "is-form-control-label": true
    }, null, 8, ["content"]),
    _createVNode(_component_UserPortalSwitchButton, {
      enabled: _ctx.enabled,
      enabledValue: _ctx.enabledValue,
      disabledValue: _ctx.disabledValue,
      onUpdatedValue: _ctx.updateValue
    }, null, 8, ["enabled", "enabledValue", "disabledValue", "onUpdatedValue"])
  ]))
}