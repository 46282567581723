import BaseApi from "@/api/BaseApi";
import ApiEndpoints from "@/constants/apiEndpoints";
import User from "@/model/User";
import UserProperties from "@/model/UserProperties";
import UserPassword from "@/model/UserPassword";
import UserWithGroups from "@/model/UserWithGroups";

class UserApi extends BaseApi {

  public async createUser(userWithGroups: UserWithGroups): Promise<Response> {
    return await this.post(ApiEndpoints.USER, userWithGroups);
  }

  public async updateUser(userWithGroups: UserWithGroups): Promise<Response> {
    const userProperties: UserProperties = new UserProperties(userWithGroups.user, userWithGroups.userGroups);
    return await this.put(ApiEndpoints.USER, userProperties, [userWithGroups.user.username]);
  }

  public async resetUserPassword(userPassword: UserPassword): Promise<Response> {
    return await this.post(
      ApiEndpoints.USER_PASSWORD,
      {
        password: userPassword.password,
        temporary: userPassword.isTemporary
      },
      [userPassword.username]
    );
  }

  public async deleteUser(username: string): Promise<Response> {
    return await this.delete(ApiEndpoints.USER, [username]);
  }

  public async getAllUsersInOrganization(organizationId: string): Promise<Array<User>> {
    const apiResponse: Response = await this.get (ApiEndpoints.USERS_IN_ORGANIZATION, [organizationId]);
    const users: Array<User> = UserApi.constructUserListFromJson(await apiResponse.json());
    return Promise.resolve(users);
  }

  private static constructUserListFromJson(jsonUserList: Array<any>): Array<User> {
    return jsonUserList.map(UserApi.constructUserFromJson);
  }

  private static constructUserFromJson(jsonUser: any): User {
    return new User(
      jsonUser.firstName,
      jsonUser.lastName,
      jsonUser.mail,
      jsonUser.organisationId,
      jsonUser.username,
      jsonUser.password
    )
  }

}

export default new UserApi();
