import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    type: this.type,
    class: {
            userPortalButton: true,
            disabled: _ctx.disabled,
            userPortalSimpleButton: !_ctx.triggersCriticalOperation,
            userPortalCriticalOperationButton: _ctx.triggersCriticalOperation
        },
    disabled: _ctx.disabled
  }, _toDisplayString(_ctx.title), 11, ["type", "disabled"]))
}