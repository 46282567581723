import BaseApi from "@/api/BaseApi";
import ApiEndpoints from "@/constants/apiEndpoints";

class FeaturesApi extends BaseApi {
  public async getAllFeatures(): Promise<Array<string>> {
    const apiResponse: Response = await this.get(ApiEndpoints.FEATURES);
    return await apiResponse.json();
  }
}

export default new FeaturesApi();
