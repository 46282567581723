
  import {defineComponent} from 'vue';
  import SnackbarType from "@/constants/SnackbarType";

  export default defineComponent({
    name: "UserPortalSnackbar",
    computed: {
      isDisplayed() {
        return this.$store.direct.getters.snackbarModule.isDisplayed;
      },
      message() {
        return this.$store.direct.getters.snackbarModule.message;
      },
      getClass() {
        return {
          userPortalSnackbar: true,
          info: this.$store.direct.getters.snackbarModule.type === SnackbarType.INFO,
          success: this.$store.direct.getters.snackbarModule.type === SnackbarType.SUCCESS,
          error: this.$store.direct.getters.snackbarModule.type === SnackbarType.ERROR,
        };
      }
    },
    methods: {
      hide() {
        this.$store.direct.dispatch.snackbarModule.hideSnackbar();
      }
    }
  });
