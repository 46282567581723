import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-management" }
const _hoisted_2 = { class: "add-new-user-button-wrapper" }
const _hoisted_3 = { class: "user-management-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPortalButton = _resolveComponent("UserPortalButton")
  const _component_UserPortalTable = _resolveComponent("UserPortalTable")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h2", null, _toDisplayString(_ctx.selectedOrganisation) + " User Management", 1),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_UserPortalButton, {
        title: "Add new user",
        type: "button",
        disabled: this.isAllowedToEditUser(),
        onClick: this.createNewUser
      }, null, 8, ["disabled", "onClick"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_UserPortalTable, {
        configuration: this.userTableConfiguration,
        elements: this.users,
        "get-element-identifier": this.getUsername,
        "selected-element": _ctx.selected,
        "onUpdate:selected-element": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selected = $event))
      }, null, 8, ["configuration", "elements", "get-element-identifier", "selected-element"])
    ])
  ]))
}