import store from '../store';

export interface DialogConfiguration {
  message: string;
  yesAction(): void;
  noAction(): void;
}

export default class DialogUtils {

  static hideDialog() {
    store.dispatch.dialogModule.hideDialog();
  }

  static confirmOperation(config: DialogConfiguration) {
    store.dispatch.dialogModule.displayDialog({
      message: config.message,
      buttons: [
        {
          label: 'Yes',
          action: () => {
            config.yesAction();
            DialogUtils.hideDialog();
          }
        },
        {
          label: 'No',
          action: () => {
            if (config.noAction) {
              config.noAction();
            }
            DialogUtils.hideDialog();
          }
        }
      ]
    });
  }
}
