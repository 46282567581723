import featureRights, {FeatureRight} from "@/constants/FeatureRight";

export default class FeatureRightUtils {

  static getFeatureRightByName(featureRightName: string): FeatureRight {
    const featureRight = Object.values(featureRights).filter(right => right.name === featureRightName);
    return featureRight.length > 0 ? featureRight[0] : null as any;
  }

  static getRightsForFeature(featureName: string): Array<FeatureRight> {
    return Object.values(featureRights).filter(right => right.belongsToFeature === featureName);
  }

  static toFeatureRightNames(featureRight: FeatureRight): Array<string> {
    return featureRight.inheritedRight !== null ?
      [featureRight.name, featureRight.inheritedRight] : [featureRight.name];
  }

  static isPrerequisiteRightPresent(featureRight: FeatureRight, featureRights: Array<FeatureRight>): boolean {
    return featureRight.prerequisiteRight === null ?
      true : featureRights.some(fr => fr.name === featureRight.prerequisiteRight)
  }

  static getFeatureRightNames(featureRights: Array<FeatureRight>): Array<string> {
    const organizationFeatureNames = featureRights
      .filter(fr => this.isPrerequisiteRightPresent(fr, featureRights))
      .map(fr => this.toFeatureRightNames(fr))
      .flat();
    return Array.from(new Set(organizationFeatureNames));
  }
}