import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "userPortalLabel" }
const _hoisted_2 = {
  key: 0,
  class: "userPortalMandatoryFieldMarker"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("span", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.content), 1),
    (_ctx.isMandatoryFieldLabel)
      ? (_openBlock(), _createBlock("span", _hoisted_2, "*"))
      : _createCommentVNode("", true),
    (_ctx.isFormControlLabel)
      ? (_openBlock(), _createBlock("span", _hoisted_3, ":"))
      : _createCommentVNode("", true)
  ]))
}