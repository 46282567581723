
  import {defineComponent, PropType} from 'vue';
  import TableConfiguration from "@/model/ui/table/TableConfiguration";
  import TableAction from "@/model/ui/table/TableAction";

  export default defineComponent({
    name: "UserPortalTable",
    props: {
      configuration: {
        required: true,
        type: Object as PropType<TableConfiguration<unknown>>
      },
      elements: {
        required: true,
        type: Array as PropType<Array<Record<string, unknown>>>
      },
      getElementIdentifier: {
        required: true,
        type: Function as PropType<(element: Record<string, unknown>) => string>
      },
      selectedElement: {
        required: false,
        type: Object as PropType<Record<string, unknown>>,
        default: null
      }
    },
    methods: {
      getHeaderColumnClass(index: number): unknown {
        return {
          userPortalTableColumn: true,
          tableHeader: true,
          tableBorderLeft: index === 0
        };
      },
      getFieldColumnClass(index: number, element: Record<string, unknown>): unknown {
        return {
          userPortalTableColumn: true,
          selected: this.isElementSelected(element),
          tableBorderLeft: index === 0
        };
      },
      getActionsColumnClass(element: Record<string, unknown>): unknown {
        return {
          userPortalTableColumn: true,
          selected: this.isElementSelected(element),
          elementActions: true
        };
      },
      getActionButtonClass(action: TableAction<unknown>): unknown {
        return {
          userPortalTableActionButton: true,
          critical: action.isCriticalOperation,
          disabled: action.disabled
        };
      },
      isElementSelected(element: Record<string, unknown>): unknown {
        return !this.selectedElement ?
          false :
          this.getElementIdentifier(element) === this.getElementIdentifier(this.selectedElement);
      },
      selectElement(element: Record<string, unknown>): void {
        this.$emit('update:selectedElement', element);
      }
    }
  });
