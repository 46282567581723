import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "userPortalInputContainer row customVerticalSpacing" }
const _hoisted_2 = { style: {"margin":"0","width":"75%"} }
const _hoisted_3 = {
  key: 0,
  class: "validationMessage row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPortalLabel = _resolveComponent("UserPortalLabel")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_UserPortalLabel, {
      style: {"width":"25%"},
      content: _ctx.title,
      "is-mandatory-field-label": _ctx.isMandatory,
      "is-form-control-label": true
    }, null, 8, ["content", "is-mandatory-field-label"]),
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode("input", {
        style: {"width":"100%"},
        id: _ctx.name,
        disabled: _ctx.disabled,
        type: _ctx.type,
        class: {
                userPortalInvalidInput: !_ctx.isValid,
                userPortalInput: !_ctx.disabled,
                userPortalInputDisabled: _ctx.disabled,
                row: true
            },
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.internalValue = $event))
      }, null, 10, ["id", "disabled", "type"]), [
        [_vModelDynamic, _ctx.internalValue]
      ]),
      (this.hasValidationMessage)
        ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.validationMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}