
export default {

  USER: 'User',

  SUPER_ADMIN: 'SuperAdmin',

  UTM_ADMIN: 'UtmAdmin',

  ORGANIZATION_ADMIN: 'OrganizationAdmin'
}