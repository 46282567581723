import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "dialogContainer" }
const _hoisted_2 = { class: "dialog-content" }
const _hoisted_3 = { class: "dialog-header" }
const _hoisted_4 = { class: "dialog-title" }
const _hoisted_5 = { class: "dialog-body" }
const _hoisted_6 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("p", null, _toDisplayString(_ctx.message), 1)
      ]),
      _createVNode("div", _hoisted_6, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index) => {
          return (_openBlock(), _createBlock("button", {
            key: index,
            type: "button",
            class: _ctx.getClass(button.label),
            onClick: button.action
          }, _toDisplayString(button.label), 11, ["onClick"]))
        }), 128))
      ])
    ])
  ], 512)), [
    [_vShow, _ctx.isDisplayed]
  ])
}