
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: "UserPortalLabel",
    props: {
      content: {
        required: true,
        type: String,
        default: ''
      },
      isMandatoryFieldLabel: {
        required: false,
        type: Boolean,
        default: false
      },
      isFormControlLabel: {
        required: false,
        type: Boolean,
        default: false
      }
    }
  });
