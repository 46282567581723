import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "userPortalDropdown"
}
const _hoisted_2 = {
  key: 1,
  class: "userPortalSelect"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPortalInput = _resolveComponent("UserPortalInput")
  const _component_UserPortalOptionList = _resolveComponent("UserPortalOptionList")
  const _component_UserPortalLabel = _resolveComponent("UserPortalLabel")

  return (_openBlock(), _createBlock("div", {
    id: this.name
  }, [
    (this.searchable)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_UserPortalInput, {
            "is-mandatory": this.isMandatory,
            "is-valid": true,
            name: `${this.name}SearchInput`,
            title: this.title,
            class: "userPortalDropdownInput",
            value: this.inputValue,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (this.inputValue = $event))
          }, null, 8, ["is-mandatory", "name", "title", "value"]),
          _withDirectives(_createVNode(_component_UserPortalOptionList, {
            "contains-search-input": this.containsSearchInput,
            "get-option-displayed-value": this.getOptionDisplayedValue,
            "get-option-identifier-value": this.getOptionIdentifierValue,
            "is-dropdown": true,
            "is-option-selected": this.isOptionSelected,
            "max-height": this.maxHeight,
            name: this.name,
            options: _ctx.displayedOptions,
            "update-selected-options": this.updateSelectedOptions,
            "check-option-is-disabled": this.checkOptionIsDisabled
          }, null, 8, ["contains-search-input", "get-option-displayed-value", "get-option-identifier-value", "is-option-selected", "max-height", "name", "options", "update-selected-options", "check-option-is-disabled"]), [
            [_vShow, this.searchInputFocused]
          ])
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_UserPortalLabel, {
            content: this.title,
            "is-form-control-label": true,
            "is-mandatory-field-label": this.isMandatory
          }, null, 8, ["content", "is-mandatory-field-label"]),
          _createVNode(_component_UserPortalOptionList, {
            "contains-search-input": this.containsSearchInput,
            "get-option-displayed-value": this.getOptionDisplayedValue,
            "get-option-identifier-value": this.getOptionIdentifierValue,
            "is-option-selected": this.isOptionSelected,
            "max-height": this.maxHeight,
            name: this.name,
            options: _ctx.displayedOptions,
            "update-selected-options": this.updateSelectedOptions,
            "check-option-is-disabled": this.checkOptionIsDisabled
          }, null, 8, ["contains-search-input", "get-option-displayed-value", "get-option-identifier-value", "is-option-selected", "max-height", "name", "options", "update-selected-options", "check-option-is-disabled"])
        ]))
  ], 8, ["id"]))
}