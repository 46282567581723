import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "organisationManagement" }
const _hoisted_2 = { class: "organisationManagementForm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganisationForm = _resolveComponent("OrganisationForm")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h2", null, _toDisplayString(_ctx.editSelectedOrganisation ? 'Organization Details' : 'Create Organization'), 1),
    _createVNode("div", _hoisted_2, [
      (_ctx.editSelectedOrganisation)
        ? (_openBlock(), _createBlock(_component_OrganisationForm, {
            key: 0,
            edit: true,
            "edited-organisation-with-user": _ctx.selectedOrganisationWithUser
          }, null, 8, ["edited-organisation-with-user"]))
        : (_openBlock(), _createBlock(_component_OrganisationForm, {
            key: 1,
            edit: false
          }))
    ])
  ]))
}