
  import {defineComponent} from 'vue';
  import DocumentationApi from "@/api/DocumentationApi";
  import UserPortalButton from "@/components/common/button/UserPortalButton.vue";

  export default defineComponent({
    name: 'Documentation',
    components: {
      UserPortalButton
    },
    data: () => {
      return {
        isDataLoading: true,
        errorMessage: undefined as string | undefined,
        fileUrl: '',
      }
    },
    async mounted(): Promise<void> {
      await this.fetchDocumentationContent();
    },
    methods: {
      async fetchDocumentationContent(): Promise<void> {
        try {
          this.isDataLoading = true;
          this.errorMessage = undefined;
          const content: Blob = await DocumentationApi.getPdfDocumentation();
          this.fileUrl = URL.createObjectURL(content);
          this.isDataLoading = false;
        } catch (e) {
          this.isDataLoading = false;
          this.errorMessage = e.httpCode === 404 ?
            'No documentation file configured.': 'An unexpected error occurred when retrieving documentation file.'
        }
      }
    }
  });
